import { AjaxForm } from '../common/ajaxForm'
import { OnTargetView } from '../common/OnTargetView'

OnTargetView({
	targets: '[make-ajax-form]',
	evaluate: ({ element }) => {
		let form = new AjaxForm({
			form: element,
		})
	},
})
