import { Debounce } from '../debounce'
import scrollTo from '../smoothScrollTo'
import mobile from '../../helpers/isMobile'

class LinkGroupWatcher {
	elements = []

	targets = []

	onActive = () => {}

	trashhold = 30

	windowScrollDebounced = new Debounce(() => {
		this.watch()
	}, this.trashhold)

	constructor({ elements, onActive }) {
		if (elements) {
			this.elements = elements
		}
		if (onActive) {
			this.onActive = onActive
		}

		this.bindElements()

		window.addEventListener(`scroll`, () => {
			this.windowScrollDebounced.fire()
		})

		this.windowScrollDebounced.fire()
	}

	getLinkTarget(element) {
		return document.querySelector(element.getAttribute(`href`))
	}

	bindElements() {
		this.targets = this.elements.map((element, elementIndex) => {
			element.addEventListener('click', () => {
				// setTimeout(()=>{
				this.onActive(element, this)
				this.windowScrollDebounced.pause()
				scrollTo({
					element: this.targets[elementIndex],
					top: mobile() ? 120 : 180,
				}).then(() => {
					this.windowScrollDebounced.continue()
					this.windowScrollDebounced.fire()
				})
				// }, 300)
			})

			return this.getLinkTarget(element)
		})
	}

	watch() {
		let scroll = window.scrollY

		let targetIndex = -1

		let minTopCorner
		let minTopCornerIndex = 0

		let maxBottomCorner = 0
		let maxBottomCornerIndex = 0
		this.targets.map((target, index) => {
			if (!target || target.style.display == 'none') {
				return false
			}

			// console.log(target.offsetTop, scroll);

			let box = target.getBoundingClientRect()

			let topCorner = box.top + scroll
			let bottomCorner = target.offsetHeight + topCorner

			if (!minTopCorner) {
				minTopCorner = topCorner
				minTopCornerIndex = index
			} else if (minTopCorner > topCorner) {
				minTopCorner = topCorner
				minTopCornerIndex = index
			}

			if (bottomCorner > maxBottomCorner) {
				maxBottomCorner = topCorner
				maxBottomCornerIndex = index
			}

			// console.log(topCorner, bottomCorner, scroll);
			if (topCorner - (mobile() ? 120 : 180) <= scroll) {
				targetIndex = index
			} else {
			}
		})

		if (targetIndex == -1) {
			if (scroll < minTopCorner) {
				targetIndex = minTopCornerIndex
			} else {
				targetIndex = maxBottomCornerIndex
			}
		}

		this.onActive(this.elements[targetIndex], this)
	}
}

export default LinkGroupWatcher
