import isMobile from '../helpers/isMobile'
import { OnTargetView } from '../common/OnTargetView'

document.querySelectorAll('[data-show-more]')?.forEach(element => {
	if (element.hasAttribute('data-on-mobile') && !isMobile()) {
		return false
	}

	let max_height = element.getAttribute(`data-max-height`)
		? parseInt(element.getAttribute(`data-max-height`))
		: window.innerHeight * 0.4

	if (element.getBoundingClientRect().height > max_height) {
		const btn = document.createElement('div')
		const btnContainer = document.createElement('div')
		btnContainer.appendChild(btn)
		btnContainer.classList.add(`show-more-btn-container`)
		btn.classList.add(`btn`)
		// btn.classList.add(`btn--second`)
		// btn.classList.add(`btn--small`)
		btn.classList.add(`btn--outline`)
		btn.classList.add(`btn--block`)

		// add text
		btn.innerText =
			element.getAttribute('data-show-more').length > 3
				? element.getAttribute('data-show-more')
				: 'Развернуть'

		element.classList.add(`show-more`)

		element.style.height = `${max_height}px`

		element.parentNode.insertBefore(btnContainer, element.nextSibling)

		btn.addEventListener(`click`, () => {
			element.style.height = `auto`
			element.classList.add(`show`)
			btnContainer.remove()
		})
	}
})

// OnTargetView({
// 	targets: '[data-show-more]',
// 	onScroll: true,
// 	evaluate: ({ element }) => {
//
// 	},
// })
//
// document.addEventListener('DOMContentLoaded', () => {
// 	// @ts-ignore
// 	;[...document.querySelectorAll(`[data-show-more]`)].map(element => {
//
// 	})
// })
