import { render } from 'react-dom'
import React, { lazy, Suspense } from 'react'
import { Loader } from '../components/Loader'
import { retry } from '../helpers/retry'

const AuthForm = lazy(() => import('../zones/AuthForm'))
setTimeout(() => {
	if (document.querySelector(`[data-auth-form]`)) {
		render(
			<Suspense fallback={<Loader />}>
				<AuthForm />
			</Suspense>,
			document.querySelector(`[data-auth-form]`)
		)
	}
}, 0)
