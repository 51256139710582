import m from '../../helpers/moment'

export const makeUntil = ({ element }: { element: HTMLElement }) => {
	let daysTarget = element.querySelector('[data-days]')
	let daysElementsTarget = element.querySelectorAll('.days')
	let hourTarget = element.querySelector('[data-hour]')
	let minuteTarget = element.querySelector('[data-minute]')

	let times = m(element.getAttribute('data-until-mount')).subtract(3, 'hours')

	const refresh = () => {
		let now = m()
		let end = times // another date
		let duration = m.duration(end.diff(now))

		// Get Days and subtract from duration
		let days = Math.floor(duration.asDays())
		duration.subtract(m.duration(days, 'days'))

		// Get hours and subtract from duration
		// Get hours and subtract from duration
		let hours = duration.hours()
		duration.subtract(m.duration(hours, 'hours'))

		// Get Minutes and subtract from duration
		let minutes = duration.minutes()
		duration.subtract(m.duration(minutes, 'minutes'))

		// Get seconds
		let seconds = duration.seconds()
		//
		//
		// let duration = m.duration(times.diff(m()))
		// let days = parseInt(duration.asDays(), 10)
		//
		// let hours = parseInt(duration.asHours(), 10)
		//
		// // duration in minutes
		// let minutes = parseInt(duration.asMinutes(), 10) % 60

		if (days > 0) {
			daysTarget.innerHTML = days.toString().padStart(2, '0')
			// hours -= days * 24
		} else {
			daysElementsTarget?.forEach(e => e.classList.add('hide'))
		}

		hourTarget.innerHTML = hours.toString().padStart(2, '0')
		minuteTarget.innerHTML = minutes.toString().padStart(2, '0')
	}

	setInterval(refresh, 1000 * 15)
	refresh()
}
