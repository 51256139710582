import TaporInitializer from '../common/tapor/taporInitializer'
import { OnTargetView } from '../common/OnTargetView'

OnTargetView({
	targets: '.tabber--link',
	onScroll: true,
	evaluate: ({ element }) => {
		let desktop_menu_tapor = new TaporInitializer({
			elements: element.querySelectorAll('.tabber-item'),
			relative: true,
			// groupAttribute: 'btn-tapor-group',
		})
	},
})
