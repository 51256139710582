import m from '../helpers/moment'
import { OnTargetView } from '../common/OnTargetView'
import fetcher from '../common/fetcher'
import { HOST } from '../config/host'

const count48Hours = currentDay => {
	let li = window.localStorage.getItem('c48')
	if (li) {
		if (m(li).toDate() < m()) {
			let newli = m().add(48, 'hours')
			window.localStorage.setItem('c48', newli.toISOString())

			return newli.toISOString()
		}

		return li
	}

	let newli = m().add(48, 'hours')
	window.localStorage.setItem('c48', newli.toISOString())

	return newli.toISOString()
}
window.count48Hours = count48Hours
OnTargetView({
	targets: '[data-timer-to]',
	evaluate: async ({ element }) => {
		let baseElement = element
		let hourTarget = baseElement.querySelector('[data-hour]')
		let minuteTarget = baseElement.querySelector('[data-minute]')
		let secondsTarget = baseElement.querySelector('[data-seconds]')

		let toDate = baseElement.getAttribute('data-timer-to')

		if (toDate == 'every-48-hours') {
			let response = await fetcher(`${HOST}/getCountDate`, {
				method: 'POST',
				body: {},
			})

			let data = await response.json()
			toDate = data.date
		}

		let times = m(toDate)

		const refresh = () => {
			let duration = m.duration(times.diff(m()))
			let hours = parseInt(duration.asHours(), 10)

			// duration in minutes
			let minutes = parseInt(duration.asMinutes(), 10) % 60
			let seconds = parseInt(duration.asSeconds(), 10) % 60

			hourTarget.innerHTML = hours.toString().padStart(2, '0')
			minuteTarget.innerHTML = minutes.toString().padStart(2, '0')

			if (secondsTarget) {
				secondsTarget.innerHTML = seconds.toString().padStart(2, '0')
			}
		}

		setInterval(refresh, secondsTarget ? 1000 : 1000 * 15)
		refresh()
	},
})
