import Modal from '../common/modal'

document.onkeydown = async evt => {
	evt = evt || window.event
	let isEscape = false
	if ('key' in evt) {
		isEscape = evt.key === 'Escape' || evt.key === 'Esc'
	} else {
		isEscape = evt.keyCode === 27
	}
	if (isEscape) {
		if (window.activeModal) {
			await window.activeModal.close()
		}
	}
}

const openModalE = (target, onClose) => {
	return new Promise(resolve => {
		let element = document.querySelector(target)

		if (element.modal) {
			// if (typeof onClose === 'function') {
			// 	element.modal.onClose = onClose
			// }
			element.modal.open()
		} else {
			let modal = new Modal({
				target: element,
				onClose: () => {
					if (typeof onClose === 'function') {
						onClose()
					}
					resolve()
				},
			})

			modal.open()
		}
	})
}
window.openModal = async (target, onClose = undefined) => {
	await openModalE(target, onClose)
}
setTimeout(() => {
	;[...document.querySelectorAll(`[bind-modal]`)].map(element => {
		element.addEventListener('click', async event => {
			let target = document.querySelector(element.getAttribute(`bind-modal`))
			if (
				target.modal &&
				target.modal.target === window.activeModal.target &&
				window.activeModal.active
			) {
				window.activeModal.close()
				element.classList.remove('active')
			} else {
				element.classList.add('active')
				await openModalE(element.getAttribute(`bind-modal`), () =>
					element.classList.remove('active')
				)
			}
		})
	})
}, 0)
