import { OnTargetView } from './OnTargetView/index.ts'
// import m from '../helpers/moment'
import { makeUntil } from './makeUntil/index.ts'

OnTargetView({
	targets: '[data-until-mount]',
	evaluate: ({ element }) => {
		makeUntil({ element })
	},
})

// OnTargetView({
// 	targets: '[data-sale-to]',
// 	evaluate: ({ element }) => {
// 		if (window.currentUser?.has_sale) {
// 			// let baseElement = element
//
// 			element.innerHTML = `
// 		<div class="sale-until-block" data-until-mount="${window.currentUser.sale_end_at}">
// 			<div class="left"> <span class="t">Скидка!</span> <span class="d">${window.currentUser.sale_message}</span> </div>
// 			<div class="right">
// 				<div class="timer">
// 					<div class="stage-1">
// 						<div class="i" data-hour>00</div>
// 						<div class="r">ч.</div>
// 						<div class="i" data-minute>00</div>
// 						<div class="r">м.</div>
// 					</div>
// 					<div class="stage-2"> До сгорания скидки </div>
// 				</div>
// 				<a href="/#tarif" class="btn btn--outline">Выберите тариф</a>
// 			</div>
// 		</div>
// 	`
// 			let baseElement = element.querySelector('[data-until-mount]')
// 			makeUntil(baseElement)
// 		}
// 	},
// })
