import { Debounce } from '../debounce'
import scrollTo from '../smoothScrollTo'
import isMobile from '../../helpers/isMobile'

class TaporTarget {
	target = null

	contentTarget = null

	contentTargetDefaultDisplay = 'flex'

	onMoveCallback = () => {}

	constructor({ target, onMove }) {
		this.target = target

		target.taporTarget = this

		if (this.target.getAttribute(`data-target`)) {
			this.contentTarget = document.querySelector(this.target.getAttribute(`data-target`))
			this.contentTarget.taporTarget = this
			this.contentTargetDefaultDisplay = this.contentTarget.style.display
			if (this.contentTargetDefaultDisplay == 'none') {
				this.contentTargetDefaultDisplay = 'flex'
			}
		}

		if (onMove) {
			this.onMoveCallback = onMove
		}
	}

	hideContent() {
		if (this.contentTarget) {
			this.contentTarget.style.display = 'none'
		}
	}

	showContent() {
		if (this.contentTarget) {
			this.contentTarget.style.display = this.contentTargetDefaultDisplay
		}
	}

	get() {
		return this.target
	}

	setActive() {
		this.onMoveCallback()
	}
}
class TaporsGroup {
	key = null

	relative = false

	scrollTo = false

	elements = []

	currentActiveIndex = undefined

	placerNode = null

	hidePlacerDebounced = new Debounce(() => {
		this.hidePlacer()
	}, 300)

	windowResizeDebounced = new Debounce(() => {
		this.hidePlacer()
	}, 80)

	constructor({ key, elements, relative, scrollTo }) {
		this.key = key
		this.elements = elements

		if (relative) {
			this.relative = relative
		}

		if (scrollTo) {
			this.scrollTo = scrollTo
		}

		this.elements = this.elements.map((target, index) => {
			return new TaporTarget({
				target,
				onMove: () => {
					this.setActive(index)
				},
			})
		})

		let elementToActive = this.bindElements()
		this.bindPlacer()
		this.hidePlacer(true)

		window.addEventListener(`resize`, () => {
			this.windowResizeDebounced.fire()
		})

		this.setActive(elementToActive)
	}

	setActive(index) {
		this.currentActiveIndex = index
		let elementP = this.elements[index]
		if (!this.elements[index]) {
			console.log(`error throw`, this.elements[index], index)
			return false
		}
		let element = this.elements[index].get()

		if (this.scrollTo) {
			element.parentElement.parentElement.scrollTo({
				left:
					element.getBoundingClientRect().left + element.parentElement.parentElement.scrollLeft - 5,
				behavior: 'smooth',
			})
		}

		this.elements.map((elementP, elementIndex) => {
			elementP.hideContent()
		})

		elementP.showContent()

		this.elements.map((element, elementIndex) => {
			if (elementIndex == index) {
				element.get().classList.add('active')
			} else {
				element.get().classList.remove('active')
			}
		})

		this.hidePlacer()
	}

	getStyleForPlacer(element) {
		if (!element) {
			return false
		}

		let styles = {
			width: `${element.get().offsetWidth}px`,
			height: `${element.get().offsetHeight}px`,

			transform: `translateY(${element.get().offsetTop}px) translateX(${
				element.get().offsetLeft
			}px)`,
			opacity: 1,
			'border-radius': element.get().style.borderRadius,
		}

		if (this.relative) {
			// styles.position = `fixed`;
		} else {
		}

		return styles
	}

	movePlacerTo(element) {
		let styles = this.getStyleForPlacer(element)

		Object.keys(styles).map(key => {
			this.placerNode.style[key] = styles[key]
		})
	}

	onHover(element) {
		this.movePlacerTo(element)
	}

	hidePlacer(first) {
		return new Promise(resolve => {
			if (this.currentActiveIndex === undefined) {
				if (first && this.elements.length > 0) {
					this.movePlacerTo(this.elements[0])
				}

				if (this.placerNode) {
					this.placerNode.style.opacity = 0
				}
			} else {
				// placer.style.opacity = 1;
				this.movePlacerTo(this.elements[this.currentActiveIndex])
			}

			setTimeout(() => {
				resolve()
			}, 500)
		})
	}

	bindPlacer() {
		if (this.relative) {
			if (this.elements.length > 0) {
				this.placerNode = this.elements[0]
					.get()
					.parentElement.appendChild(document.createElement(`span`))
			}
		} else {
			this.placerNode = document.querySelector('body').appendChild(document.createElement(`span`))
		}

		if (this.placerNode) {
			if (this.elements.length > 0) {
				this.placerNode.classList = this.elements[0].get().classList
			}
			this.placerNode.classList.add('tapor--placer')
		}

		// this.elements.map((element, elementIndex)=>{
		//
		//     // if(element.getAttribute('btn-tapor-relative')){
		//     //     this.relative = true;
		//     // }
		//
		//     if(element.get().classList.contains('active')){
		//         this.currentActiveIndex = elementIndex;
		//         //this.hidePlacer();
		//         this.setActive(this.currentActiveIndex);
		//     }
		// });
	}

	bindElements() {
		let elementToActive = 0
		this.elements.map((element, elementIndex) => {
			element.get().tapor = this

			if (element.get().getAttribute('btn-tapor-relative') == '') {
				this.relative = true
			}

			element.get().addEventListener(
				'mousemove',
				() => {
					this.onHover(element)
				},
				false
			)
			element.get().addEventListener(
				'mouseout',
				() => {
					this.hidePlacerDebounced.fire()
				},
				false
			)
			element.get().addEventListener(
				'mouseover',
				() => {
					this.hidePlacerDebounced.clear()
				},
				false
			)

			element.get().addEventListener('click', () => {
				this.movePlacerTo(element)
			})

			if (element.get().classList.contains(`active`)) {
				elementToActive = elementIndex
			}

			if (element.contentTarget) {
				if (window.location.href.indexOf(`#${element.contentTarget.getAttribute('id')}`) > -1) {
					elementToActive = elementIndex
					scrollTo({
						element: element.contentTarget,
						top: isMobile() ? 120 : 80,
					})
				}
			}
		})

		return elementToActive

		// this.elements[elementToActive].setActive();
	}
}

export default TaporsGroup
