import { OnTargetView } from '../common/OnTargetView'

function fallbackCopyTextToClipboard(text) {
	let textArea = document.createElement('textarea')
	textArea.value = text

	// Avoid scrolling to bottom
	textArea.style.top = '0'
	textArea.style.left = '0'
	textArea.style.position = 'fixed'

	document.body.appendChild(textArea)
	textArea.focus()
	textArea.select()

	try {
		let successful = document.execCommand('copy')
		let msg = successful ? 'successful' : 'unsuccessful'
		console.log(`Fallback: Copying text command was ${msg}`)
	} catch (err) {
		console.error('Fallback: Oops, unable to copy', err)
	}

	document.body.removeChild(textArea)
}
function copyTextToClipboard(text) {
	if (!navigator.clipboard) {
		fallbackCopyTextToClipboard(text)
		return
	}
	navigator.clipboard.writeText(text).then(
		function () {
			console.log('Async: Copying to clipboard was successful!')
		},
		function (err) {
			console.error('Async: Could not copy text: ', err)
		}
	)
}

OnTargetView({
	targets: `[data-target-copy]`,
	onScroll: true,
	evaluate: ({ element }) => {
		element.addEventListener('click', () => {
			copyTextToClipboard(document.querySelector(element.getAttribute(`data-target-copy`)).value)
			window.makeSnack(`success`, `Скопировано в буфер обмена`)
		})
	},
})
