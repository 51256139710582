import React, { lazy, Suspense, FC } from 'react'
import Skeleton from 'react-loading-skeleton'

const Element = lazy(() => import('./buyGiftModal.tsx'))

const MatrixSkeleton = () => {
	return (
		<div>
			<Skeleton height={249} />
		</div>
	)
}
export const makeBuyGiftModal = type => {
	window.makeReactModal({
		icon: false,
		closeBtn: false,
		type: 'large large--auto',
		allowOverflow: false,
		reactElement: (
			<Suspense fallback={<MatrixSkeleton />}>
				<Element type={type} />
			</Suspense>
		),
	})
}
