window.makeSupportModal = addition => {
	let target = document.querySelector('#supportModal')
	target.querySelector(`[name=addition]`).value = JSON.stringify(addition)
	target.querySelector(`[name=url]`).value = window.location.href

	if (window.currentUser) {
		if (window.currentUser.name && target.querySelector(`[name=name]`)) {
			target.querySelector(`[name=name]`).value = window.currentUser.name
			target.querySelector(`[name=name]`).dispatchEvent(new Event('change'))
		}
		if (window.currentUser.email && target.querySelector(`[name=email]`)) {
			target.querySelector(`[name=email]`).value = window.currentUser.email
			target.querySelector(`[name=email]`).dispatchEvent(new Event('change'))
		}
	}
	window.openModal('#supportModal')
}
