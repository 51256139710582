import scrollTo from '../common/smoothScrollTo'
import { OnTargetView } from '../common/OnTargetView'

const getIndexVal = value => {
	if (!value) {
		return 'default'
	}
	return value
}
document.querySelectorAll('[data-stepper], .stepper')?.forEach(element => {
	let stepperBase = element
	let stepperIndex = getIndexVal(stepperBase.getAttribute('data-stepper'))

	let currentStep = 0

	let steps = {}

	;[...stepperBase.querySelectorAll('.stepper-step')]
		.filter(step => {
			let stepIndex = getIndexVal(step.getAttribute('data-index'))

			if (stepperIndex !== stepIndex) {
				return false
			}

			return true
		})
		.map((step, indexNumber) => {
			let index = `step-${indexNumber + 1}`
			let className = [...step.classList].find(item => /step-(.+)/gi.test(item))?.toString()
			if (className) {
				index = className
			}
			if (!steps[index]) {
				steps[index] = []
			}

			steps[index].push(step)
		})

	const refreshClassActiveIndex = index => {
		stepperBase.querySelectorAll('[data-to-step]')?.forEach(element => {
			let stepIndex = getIndexVal(element.getAttribute('data-index'))

			if (stepperIndex !== stepIndex) {
				return false
			}
			element.classList.remove('active')
		})
		let query = `[data-to-step="${index}"]`

		if (Object.keys(steps).findIndex(e => e == index) !== -1) {
			query = `${query}, [data-to-step="${Object.keys(steps).findIndex(e => e == index)}"]`
		}
		stepperBase.querySelectorAll(query)?.forEach(element => {
			let stepIndex = getIndexVal(element.getAttribute('data-index'))

			if (stepperIndex !== stepIndex) {
				return false
			}
			element.classList.add('active')
		})
	}

	const goToStep = (oldIndex, scroll = true) => {
		let index =
			parseInt(oldIndex, 10).toString() == oldIndex
				? `step-${parseInt(oldIndex, 10) + 1}`
				: oldIndex
		refreshClassActiveIndex(oldIndex)
		Object.keys(steps).map(key => {
			if (key === index) {
				steps[key].map(el => {
					el.classList.add('active')
				})
			} else {
				steps[key].map(el => {
					el.classList.remove('active')
				})
			}
		})

		console.log('index', index)

		if (!stepperBase.hasAttribute('data-stepper-disable-scroll') && scroll) {
			setTimeout(() => scrollTo({ element: steps[index][0] }), 100)
		}

		currentStep = index
	}

	stepperBase.querySelectorAll('[data-to-step]')?.forEach(toStep => {
		let stepIndex = getIndexVal(toStep.getAttribute('data-index'))

		if (stepperIndex !== stepIndex) {
			return false
		}

		toStep.addEventListener('click', () => {
			goToStep(toStep.getAttribute('data-to-step'))
		})
	})
	stepperBase.querySelectorAll('[data-prev-step]')?.forEach(toStep => {
		let stepIndex = getIndexVal(toStep.getAttribute('data-index'))
		if (stepperIndex !== stepIndex) {
			return false
		}
		toStep.addEventListener('click', () => {
			let indexes = Object.keys(steps)
			let currentIndex = indexes.findIndex(e => e == currentStep)
			goToStep(currentIndex === 0 ? indexes[indexes.length - 1] : indexes[currentIndex - 1])
		})
	})
	stepperBase.querySelectorAll('[data-next-step]')?.forEach(toStep => {
		let stepIndex = getIndexVal(toStep.getAttribute('data-index'))
		if (stepperIndex !== stepIndex) {
			return false
		}
		toStep.addEventListener('click', () => {
			let indexes = Object.keys(steps)
			let currentIndex = indexes.findIndex(e => e == currentStep)
			goToStep(currentIndex >= indexes.length ? indexes[0] : indexes[currentIndex + 1])
		})
	})

	goToStep(Object.keys(steps)[0], false)
})
