import { HOST } from '../config/host'
import { niceNumber } from '../helpers/niceNumber'
import { OnTargetView } from '../common/OnTargetView'
import fetcher from '../common/fetcher'

const sleep = time => {
	return new Promise(resolve => setTimeout(() => resolve(), time))
}
const updateVal = async (element, to, amount = 10) => {
	let currentVal = parseInt(
		element.innerText.replace(/\s/g, '').replace(/,/g, '').replace(/\./g, '').replace(/-/g, '')
	)
	// console.log(element.innerText, currentVal)
	let diff = to - currentVal

	if (diff !== 0) {
		let step = 25
		let steps = (amount * 1000) / step

		let toSum = diff / steps

		for (let i = 0; i < steps; i++) {
			if (i === steps - 1) {
				currentVal = to
			} else {
				currentVal += toSum
			}

			if (
				(diff >= 0 && parseInt(element.innerText.replace(/\s/g, '')) < Math.ceil(currentVal)) ||
				(diff < 0 && parseInt(element.innerText.replace(/\s/g, '')) > Math.ceil(currentVal))
			) {
				element.innerHTML = niceNumber(Math.ceil(currentVal))
			}

			await sleep(step)
		}

		element.innerHTML = niceNumber(Math.ceil(to))
	}
}

const refreshStatBlock = async (base, amount) => {
	let response = await fetcher(`${HOST}/api/getStat`, {
		method: 'POST',
	})

	let data = await response.json()
	let elements = base.querySelectorAll('[data-stat]')
	elements?.forEach((element, index) => {
		setTimeout(() => {
			let key = element.getAttribute('data-stat')
			if (data[key]) {
				updateVal(element, data[key], amount)
			}
		}, index * 250)
	})

	setTimeout(() => refreshStatBlock(base, 20), elements.length * 500 + amount * 1000 + 1100)
}

OnTargetView({
	targets: '.stat-block',
	onScroll: true,
	evaluate: ({ element }) => {
		refreshStatBlock(element, 0.5)
	},
})
