// import { tns } from 'tiny-slider/src/tiny-slider'
import isMobile from '../helpers/isMobile'
import { OnTargetView } from '../common/OnTargetView'

document
	.querySelectorAll(isMobile() ? '.simply-slider:not(.simply-slider--nomobile)' : '.simply-slider')
	?.forEach(element => {
		const style = getComputedStyle(document.body)

		let itemsSpacing = 20
		let currentItem = 0

		if (element.hasAttribute('data-gutter')) {
			itemsSpacing = parseInt(element.getAttribute('data-gutter'), 10)
			element.style.setProperty('--items-spacer', `${itemsSpacing}px`)
		}
		//
		// element.style = styles

		let container = element.querySelector(`.slider`)
		let items = container.querySelectorAll(`.item`)

		let itemWidth = 0

		const calcWidth = () =>
			items?.forEach(e => {
				if (itemWidth < e.clientWidth) {
					itemWidth = e.clientWidth
				}
			})

		calcWidth()

		let leftBtns = element.querySelectorAll('.action .prev, .controls .prev')
		let rightBtns = element.querySelectorAll('.action .next, .controls .next')

		const classRefresh = () => {
			let maxScrollLeft = container.scrollWidth - container.clientWidth

			if (container.scrollLeft === 0) {
				element.classList.add('scrolled-left')
				element.classList.remove('scrolled-right')
			} else if (container.scrollLeft >= maxScrollLeft) {
				element.classList.add('scrolled-right')
				element.classList.remove('scrolled-left')
			} else {
				element.classList.remove('scrolled-left')
				element.classList.remove('scrolled-right')
			}

			if (maxScrollLeft === 0) {
				element.classList.add('hide-controls')
			} else {
				element.classList.remove('hide-controls')
			}
		}

		const scrollTo = (left = false) => {
			calcWidth()

			if (left) {
				container.scrollLeft -= itemWidth + itemsSpacing
			} else {
				container.scrollLeft += itemWidth + itemsSpacing
			}

			classRefresh()
		}

		if (leftBtns.length > 0) {
			leftBtns?.forEach(leftBtn =>
				leftBtn.addEventListener('click', () => {
					scrollTo(true)
				})
			)
		}

		if (rightBtns.length > 0) {
			rightBtns?.forEach(rightBtn =>
				rightBtn.addEventListener('click', () => {
					scrollTo(false)
				})
			)
		}

		container.addEventListener('scroll', () => {
			classRefresh()
		})

		window.addEventListener('resize', () => {
			classRefresh()
		})

		if (element.hasAttribute('data-edge-padding-container')) {
			const refreshLeftSpacing = () => {
				if (items[0]) {
					if (!isMobile()) {
						items[0].style.marginLeft = `${
							window.innerWidth / 2 +
							parseInt(style.getPropertyValue('--container-margin')) -
							parseInt(style.getPropertyValue('--container-max-width')) / 2
						}px`
					} else if (element.classList.contains('simply-slider--nomobile')) {
						items[0].style.marginLeft = 0
					} else {
						items[0].style.marginLeft = `${parseInt(
							style.getPropertyValue('--container-margin')
						)}px`
					}
				}
			}

			refreshLeftSpacing()

			window.addEventListener('resize', () => {
				refreshLeftSpacing()
			})
		}

		classRefresh()
	})
// OnTargetView({
// 	targets: isMobile() ? '.simply-slider:not(.simply-slider--nomobile)' : '.simply-slider',
// 	evaluate: ({ element }) => {
// 		const style = getComputedStyle(document.body)
//
// 		let itemsSpacing = 20
//
// 		if (element.hasAttribute('data-gutter')) {
// 			itemsSpacing = parseInt(element.getAttribute('data-gutter'), 10)
// 			element.style.setProperty('--items-spacer', `${itemsSpacing}px`)
// 		}
// 		//
// 		// element.style = styles
//
// 		let container = element.querySelector(`.slider`)
// 		let items = container.querySelectorAll(`.item`)
//
// 		let itemWidth = 0
//
// 		const calcWidth = () =>
// 			items?.forEach(e => {
// 				if (itemWidth < e.clientWidth) {
// 					itemWidth = e.clientWidth
// 				}
// 			})
//
// 		calcWidth()
//
// 		let leftBtns = element.querySelectorAll('.action .prev, .controls .prev')
// 		let rightBtns = element.querySelectorAll('.action .next, .controls .next')
//
// 		const classRefresh = () => {
// 			let maxScrollLeft = container.scrollWidth - container.clientWidth
//
// 			if (container.scrollLeft === 0) {
// 				element.classList.add('scrolled-left')
// 				element.classList.remove('scrolled-right')
// 			} else if (container.scrollLeft >= maxScrollLeft) {
// 				element.classList.add('scrolled-right')
// 				element.classList.remove('scrolled-left')
// 			} else {
// 				element.classList.remove('scrolled-left')
// 				element.classList.remove('scrolled-right')
// 			}
//
// 			if (maxScrollLeft === 0) {
// 				element.classList.add('hide-controls')
// 			} else {
// 				element.classList.remove('hide-controls')
// 			}
// 			// console.log(container.scrollLeft, maxScrollLeft)
// 		}
//
// 		const scrollTo = (left = false) => {
// 			calcWidth()
//
// 			if (left) {
// 				container.scrollLeft -= itemWidth + itemsSpacing
// 			} else {
// 				container.scrollLeft += itemWidth + itemsSpacing
// 			}
//
// 			classRefresh()
// 		}
//
// 		if (leftBtns.length > 0) {
// 			leftBtns?.forEach(leftBtn =>
// 				leftBtn.addEventListener('click', () => {
// 					scrollTo(true)
// 				})
// 			)
// 		}
//
// 		if (rightBtns.length > 0) {
// 			rightBtns?.forEach(rightBtn =>
// 				rightBtn.addEventListener('click', () => {
// 					scrollTo(false)
// 				})
// 			)
// 		}
//
// 		container.addEventListener('scroll', () => {
// 			classRefresh()
// 		})
//
// 		window.addEventListener('resize', () => {
// 			classRefresh()
// 		})
//
// 		if (element.hasAttribute('data-edge-padding-container')) {
// 			const refreshLeftSpacing = () => {
// 				if (items[0]) {
// 					if (!isMobile()) {
// 						items[0].style.marginLeft = `${
// 							window.innerWidth / 2 +
// 							parseInt(style.getPropertyValue('--container-margin')) -
// 							parseInt(style.getPropertyValue('--container-max-width')) / 2
// 						}px`
// 					} else if (element.classList.contains('simply-slider--nomobile')) {
// 						items[0].style.marginLeft = 0
// 					} else {
// 						items[0].style.marginLeft = `${parseInt(
// 							style.getPropertyValue('--container-margin')
// 						)}px`
// 					}
// 				}
// 			}
//
// 			refreshLeftSpacing()
//
// 			window.addEventListener('resize', () => {
// 				refreshLeftSpacing()
// 			})
// 		}
//
// 		classRefresh()
// 	},
// })
