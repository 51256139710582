window.makeModalPickInstallment = url => {
	if (!window.currentUser) {
		window.makeMessageModal({
			icon: false,
			closeBtn: false,
			html: `
        <div class="installment-picker">
			<div class="typography typography--left">
				<h2>Войдите или зарегистрируйтесь</h2>
				<p>Для оформления рассрочки нужно иметь аккаунт на сайте</p>
				<div class="content">
					<div class="btn" onclick="window.location.href = '/lk';">Войти</div>
					<div class="btn" onclick="window.location.href = '/lk#register';">Зарегистрироваться</div>
				</div>
			</div>
		</div>
        `,
		})
	} else {
		window.makeMessageModal({
			icon: false,
			closeBtn: false,
			html: `
        <div class="installment-picker">
			<div class="typography typography--left">
				<h2>Выберите срок рассрочки</h2>
				
				<div class="content">
					<div class="btn" onclick="window.location.href = '${url}/tinkoff_credit?code=installment_0_0_3_5,19';">на 3 месяца</div>
					<div class="btn" onclick="window.location.href = '${url}/tinkoff_credit?code=installment_0_0_6_8,84';">на 6 месяцев</div>
					<div class="btn" onclick="window.location.href = '${url}/tinkoff_credit?code=installment_0_0_10_13,42';">на 10 месяцев</div>
					<div class="btn" onclick="window.location.href = '${url}/tinkoff_credit?code=installment_0_0_12_15,59';">на 12 месяцев</div>
				</div>
			</div>
		</div>
        `,
		})
	}
}
