import { copyTextToClipboard } from '../common/copyToClipboard'
import { OnTargetView } from '../common/OnTargetView'

OnTargetView({
	targets: '[target-copy]',
	onScroll: true,
	evaluate: ({ element }) => {
		element.addEventListener(`click`, () => {
			copyTextToClipboard(element.innerText)
			window.makeSnack('success', `Скопировано в буфер обмена`)
		})
	},
})
