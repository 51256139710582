import mobile from '../../helpers/isMobile'
import { Debounce } from '../debounce'

class Spoiler {
	target = null

	active = false

	head = null

	content = null

	onlyMobile = false

	clickEvent = null

	constructor({ target, head, content, onlyMobile }) {
		this.target = target

		if (head) {
			this.head = head
		} else {
			this.head = this.target.querySelector('.spoiler-head')
		}

		if (content) {
			this.content = content
		} else {
			this.content = this.target.querySelector('.spoiler-content')
		}

		this.target.spoiler = this
		this.head.spoiler = this
		this.content.spoiler = this

		this.onlyMobile = onlyMobile

		if (this.onlyMobile) {
			this.windowResizeDebounced = new Debounce(() => {
				this.bind()
			}, 300)

			window.addEventListener(`resize`, () => {
				this.windowResizeDebounced.fire()
			})

			setTimeout(() => {
				this.windowResizeDebounced.fire()
			})
		}

		this.bind()
	}

	bind() {
		if (this.onlyMobile) {
			if (mobile()) {
				this.target.classList.add('spoiler-wrapper')
				this.bindEvents()
			} else {
				this.target.classList.remove('spoiler-wrapper')
				this.unbindEvents()
			}
		} else {
			this.target.classList.add('spoiler-wrapper')

			this.bindEvents()
		}
	}

	handleToggle() {
		this.active = !this.active

		if (this.active) {
			this.target.classList.add('active')
		} else {
			this.target.classList.remove('active')
		}
	}

	clickListener() {
		this.spoiler.handleToggle()
	}

	bindEvents() {
		this.head.addEventListener('click', this.clickListener)
	}

	unbindEvents() {
		this.head.removeEventListener('click', this.clickListener)
	}
}

export default Spoiler
