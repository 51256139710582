import fetcher from '../common/fetcher'
import { HOST } from '../config/host'

export const acceptPolicy = async () => {
	let tipCookie = document.getElementById('tipCookie')
	if (tipCookie) {
		tipCookie.classList.remove('show')
	}
	window.localStorage.setItem('acceptPolicy', 'true')
	let response = await fetcher(`${HOST}/user/acceptPolicy`, {
		method: 'POST',
		body: '',
	})
}
