import TaporInitializer from '../common/tapor/taporInitializer'
import LinkGroupWatcher from '../common/linkGroupWatcher'
import { OnTargetView } from '../common/OnTargetView'

OnTargetView({
	targets: `.page-tabber-contents-wrapper`,
	onScroll: true,
	evaluate: ({ element }) => {
		let links = [...element.querySelectorAll('.content-tabber-wrapper .tabber .tabber-item')]
		let content_tapor = new TaporInitializer({
			elements: links,
			relative: true,
			scrollTo: true,
			// groupAttribute: 'btn-tapor-group',
		})

		new LinkGroupWatcher({
			elements: links,
			onActive: element => {
				// setTimeout(()=>{
				let target = document.querySelector(element.getAttribute('href'))

				if (target) {
					if (target.taporTarget) {
						target.taporTarget.setActive()
					}
				}

				element.taporTarget.setActive()
				// }, 0)
			},
		})
	},
})
