import { tns } from 'tiny-slider'

let carousel = document.querySelector('.feedback-new-block')
if (carousel) {
	let slider = tns({
		container: '.feedback-new-block .slider-container .slider',
		items: 1,
		slideBy: 'page',
		lazyload: true,
		autoplay: true,
		controls: false,
		navPosition: 'bottom',
		navContainer: '.feedback-new-block .controls .navs',
		autoplayTimeout: 5000,
	})

	carousel.querySelector('.prev').addEventListener('click', () => {
		slider.goTo('prev')
	})
	carousel.querySelector('.next').addEventListener('click', () => {
		slider.goTo('next')
	})
}
