import React, { FC, useEffect, useState } from 'react'
import fetcher from '../../common/fetcher'
import { HOST } from '../../config/host'
import { MatrixResult } from '../Matrix/show'
import { DropZone } from '../../components/DropZone'
import { DropDown } from '../../components/DropDown'
import { openLink } from '../../imports/openLink'
import { declOfNum, niceNumber, NicePrice } from '../../helpers/niceNumber'
import { useMedia } from '../Matrix/AdditionBlocks/NewMatrixHeadCombined/hooks/media'
import { OnTargetView } from '../../common/OnTargetView/index.ts'
import m from '../../helpers/moment'
import { getWithSale } from '../../helpers/getWithSale'
import { makeUntil } from '../../common/makeUntil/index.ts'

function findGetParameter(parameterName) {
	let result = null
	let tmp = []
	location.search
		.substr(1)
		.split('&')
		.forEach(function (item) {
			tmp = item.split('=')
			if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1])
		})
	return result
}

export interface IUser {
	id: number
	role_key: 'member' | 'admin' | 'manager' | 'translator'
	name: string
	day_sub: number
	email: string
	avatar_img: string
	avatar_url: string
	matrix_cover_url: string
	bonus: number
	ref_balance: number
	ref_accepted_at: string | null
	has_sale: boolean
	sale_percent: number
	sale_end_at: string
	sale_message: string
	first_buy: boolean
	has_free_course: boolean
	policy_accepted_at: string | null
}

const AccountZone: FC<{
	footer?: boolean
}> = ({ footer }) => {
	const [currentUser, setCurrentUser] = useState<IUser | undefined>(window?.currentUser)

	const isAuth = !!currentUser

	const { turnToNormal, turnToDesktop, if_mobile, is_mobile, is_tablet, setIsMobile, setIsTablet } =
		useMedia()

	useEffect(() => {
		;(async () => {
			if (!footer && currentUser === undefined) {
				try {
					let body = {}

					let utmTemp = {
						utm_source: findGetParameter('utm_source'),
						utm_medium: findGetParameter('utm_medium'),
						utm_campaign: findGetParameter('utm_campaign'),
						utm_content: findGetParameter('utm_content'),
						utm_term: findGetParameter('utm_term'),
					}

					let utm = {}

					Object.keys(utmTemp).map(key => {
						if (typeof utmTemp[key] === 'string' && utmTemp[key].length > 0) {
							utm[key] = utmTemp[key]
						}
					})

					if (Object.keys(utm).length > 0) {
						body.utm = utm
					}

					if (findGetParameter('r')) {
						body.ref = findGetParameter('r')

						fetcher(`${HOST}/api/batchRefHistory`, {
							method: 'POST',
							body: {
								link: window.location.href,
								...body,
							},
						})
					}

					let response = await fetcher(`${HOST}/user/get`, {
						method: 'POST',
						body,
					})

					let data: {
						me: IUser
						mobile_menu: string
						mobile_menu_profile: string
					} = await response.json()

					window.currentUser = data.me

					setCurrentUser(data.me)

					if (data.me) {
						document.querySelectorAll('.n-auth').forEach((el, index) => {
							// eslint-disable-next-line no-param-reassign
							// @ts-ignore
							el.classList.add('hide-force')
						})
						document.querySelectorAll('.o-auth').forEach((el, index) => {
							// eslint-disable-next-line no-param-reassign
							// @ts-ignore
							el.classList.remove('hide-force')
						})

						if (data.me.role_key === 'admin') {
							document.querySelectorAll('.o-admin').forEach((el, index) => {
								// eslint-disable-next-line no-param-reassign
								// @ts-ignore
								el.classList.remove('hide-force')
							})
						}

						if (!data.me.policy_accepted_at) {
							let tipCookie = document.querySelector('.tip-cookie')
							if (tipCookie) {
								tipCookie.classList.add('show')
							}
						}

						// if (data.me.has_free_course) {
						// 	document.querySelectorAll('.block-free-course')?.forEach(element => {
						// 		element.style.display = 'none'
						// 	})
						// }
					} else {
						document.querySelectorAll('.n-auth').forEach((el, index) => {
							// eslint-disable-next-line no-param-reassign
							// @ts-ignore
							el.classList.remove('hide-force')
						})
						document.querySelectorAll('.o-auth').forEach((el, index) => {
							// eslint-disable-next-line no-param-reassign
							// @ts-ignore
							el.classList.add('hide-force')
						})

						if (window.localStorage.getItem('acceptPolicy') === null) {
							let tipCookie = document.querySelector('.tip-cookie')
							if (tipCookie) {
								tipCookie.classList.add('show')
							}
						}
					}

					if (window?.currentUser?.sale_for_new_course) {
						document.querySelectorAll('[data-new-course]').forEach((el, index) => {
							el.classList.remove('hide-force')
						})
					}

					if (window?.currentUser?.has_free_course) {
						document.querySelectorAll('.hide-if-has-free-course').forEach((el, index) => {
							el.classList.add('hide-force')
						})
					} else {
						document.querySelectorAll('.show-if-has-free-course').forEach((el, index) => {
							el.classList.add('hide-force')
						})
					}

					if (window.currentUser.has_sale) {
						document.querySelectorAll('[data-can-salable]').forEach((el, index) => {
							let value = parseInt(el.innerHTML.replace(/( |-|руб\.)/gi, ''), 10)
							let sale_value = getWithSale(value, window.currentUser?.sale_percent)

							el.innerHTML = `
							<div class="with-sale">${niceNumber(sale_value)} руб.</div>
							<div class="without-sale">${niceNumber(value)} руб.</div>
							`
						})

						document.querySelectorAll('[data-can-salable-base]').forEach((el, index) => {
							let value = parseInt(el.innerHTML.replace(/( |-|руб\.)/gi, ''), 10)
							let sale_value = getWithSale(value, window.currentUser?.sale_percent)

							el.innerHTML = niceNumber(sale_value)
						})

						OnTargetView({
							targets: '[data-sale-to]',
							evaluate: ({ element }) => {
								// if(window.currentUser.has_sale){
								let baseElement = element

								baseElement.innerHTML = `
		<div class="sale-until-block" data-until-mount="${window.currentUser.sale_end_at}">
			<div class="left"> <span class="t">Скидка!</span> <span class="d">${window.currentUser.sale_message}</span> </div>
			<div class="right">
				<div class="timer">
					<div class="stage-1">
						<div class="i days" data-days>00</div>
						<div class="r days">д.</div>
						<div class="i" data-hour>00</div>
						<div class="r">ч.</div>
						<div class="i" data-minute>00</div>
						<div class="r">м.</div>
					</div>
					<div class="stage-2"> До сгорания скидки </div>
				</div>
				<a href="/#tarif" class="btn btn--outline">Выберите тариф</a>
			</div>
		</div>
	`
								makeUntil({ element: baseElement.querySelector('[data-until-mount]') })
							},
						})
					}

					// if (!footer) {
					if (document.querySelector('[data-mobile-menu]')) {
						document.querySelector('[data-mobile-menu]').innerHTML = data.mobile_menu
					}
					if (document.querySelector('[data-mobile-menu-profile]')) {
						document.querySelector('[data-mobile-menu-profile]').innerHTML =
							data.mobile_menu_profile
					}
					// }
				} catch (e) {}
			}

			if (footer) {
				const fetchUser = () => {
					if (window?.currentUser) {
						setCurrentUser(window?.currentUser)
					} else {
						setTimeout(fetchUser, 100)
					}
				}

				fetchUser()
			}
		})()
	}, [currentUser, footer])

	const [mobileProfileActive, setMobileProfileActive] = useState<boolean>(false)

	if (footer) {
		if (isAuth) {
			return (
				<>
					<a
						href="/#matrixForm"
						className="btn btn--primary btn--large-width"
						id="matrixActionFooter"
					>
						Рассчитать матрицу
					</a>
				</>
			)
		}
		return (
			<>
				<a
					href="/#matrixForm"
					className="btn btn--primary btn--large-width"
					id="matrixActionFooter"
				>
					Рассчитать матрицу
				</a>

				<a
					href="#"
					onClick={() => {
						openLink(`${HOST}/`)
					}}
					className="btn btn--second btn--outline btn--icon-left"
				>
					<div className="icon icon--login" />
					Вход
				</a>
			</>
		)
	}

	if (isAuth) {
		return (
			<>
				{!is_mobile ? (
					<a
						href="/#matrixForm"
						className="btn btn--primary"
						onClick={() => {
							window.reachGoal ? window.reachGoal('register_add_months') : null
						}}
					>
						Рассчитать матрицу
					</a>
				) : undefined}

				<div className="user-profile">
					{is_mobile ? (
						<div className={`dropdown ${mobileProfileActive ? 'active' : ''}`}>
							<div
								className="current"
								onClick={async () => {
									if (mobileProfileActive) {
										window.activeModal.close()
										setMobileProfileActive(false)
									} else {
										setMobileProfileActive(true)
										await window.openModal('.modal-mobile-menu-profile', () =>
											setMobileProfileActive(false)
										)
										setMobileProfileActive(false)
									}
								}}
							>
								<div
									className="avatar-wraper tip"
									dangerouslySetInnerHTML={{
										__html: currentUser.avatar_img,
									}}
								/>
							</div>
						</div>
					) : (
						<DropDown
							titleRender={() => (
								<div
									className="avatar-wraper tip"
									dangerouslySetInnerHTML={{
										__html: currentUser.avatar_img,
									}}
								/>
							)}
						>
							<>
								<div className="profile-card">
									<div
										className="avatar-wrap"
										dangerouslySetInnerHTML={{
											__html: currentUser.avatar_img,
										}}
									/>
									<div className="desc">
										<span className="t">{currentUser.name}</span>
										<span className="d">{currentUser.email}</span>
									</div>
								</div>

								<div className="item separator" />

								{['admin', 'manager', 'translator'].includes(currentUser.role_key) ? (
									<a href={`${HOST}/admin`} className="item item--simple">
										Админ-панель
									</a>
								) : undefined}

								<a href={`${HOST}/user`} className="item item--simple">
									Профиль
								</a>
								<a href={`${HOST}/user/subscribe`} className="item item--simple">
									Мой тариф
								</a>

								<a href={`${HOST}/user/bonus`} className="item item--simple">
									Бонусный счет
									<span className="label label--primary">
										{niceNumber(currentUser.bonus)}{' '}
										{declOfNum(currentUser.bonus, ['бонус', 'бонуса', 'бонусов'])}
									</span>
								</a>
								<a href={`${HOST}/user/refCabinet`} className="item item--simple">
									Партнерская программа
									{currentUser.ref_accepted_at ? (
										<span className="label label--primary">
											{niceNumber(currentUser.ref_balance)}{' '}
											{declOfNum(currentUser.ref_balance, ['бонус', 'бонуса', 'бонусов'])}
										</span>
									) : (
										<span className="label label--primary">Новый</span>
									)}
								</a>

								<div className="item separator" />

								<a href="/lk/user/calculators" className="item item--simple">
									Калькуляторы матриц
									<span className="label label--primary">Новый</span>
								</a>

								<a href={`${HOST}/user/myMatrix`} className="item item--simple">
									Сохраненные матрицы
								</a>

								<div className="item separator" />

								<a href="/lk/user/learning" className="item item--simple">
									Обучение
									<span className="label label--primary">Новый</span>
								</a>

								<a href="/lk/user/tools" className="item item--simple">
									Инструменты нумеролога
								</a>

								<div className="item separator" />

								<a href={`${HOST}/user/support`} className="item item--simple">
									Написать в поддержку
								</a>

								<a href={`${HOST}/user/writeFeedback`} className="item item--simple">
									Написать отзыв
								</a>

								{/* @include('parts.parts-user-links') */}

								<div className="item separator separator--empty" />

								<a href={`${HOST}/user/logout`} className="item item--simple">
									<span className="icon icon--logout" />
									Выйти
								</a>
							</>
						</DropDown>
					)}
				</div>
			</>
		)
	}

	if (is_mobile) {
		return (
			<>
				{window.location.href !== 'https://matrica-sudby.ru/lk/' ? (
					<div
						onClick={() => {
							window.reachGoal ? window.reachGoal('auth_header') : null
							openLink(`${HOST}/`)
						}}
						className="btn btn--primary btn--outline btn--xsmall"
					>
						Войти
					</div>
				) : undefined}
			</>
		)
	}
	return (
		<>
			<a
				href="/#matrixForm"
				className="btn btn--primary"
				onClick={() => {
					window.reachGoal ? window.reachGoal('register_add_months') : null
				}}
			>
				Рассчитать матрицу
			</a>

			<a
				onClick={() => {
					window.reachGoal ? window.reachGoal('auth_header') : null
					openLink(`${HOST}/`)
				}}
				className="btn btn--second btn--outline btn--icon-left"
			>
				<div className="icon icon--login" />
				Вход
			</a>
		</>
	)
}
export default AccountZone
