import React from 'react'
import { HOST } from '../config/host'

window.makeModalBuy = ({ type, amount, course_id }) => {
	let link =
		type === 'buyCourse'
			? `${HOST}/user/buyCourse/${course_id}/${amount}`
			: `${HOST}/user/buySubscribe/${type}/${amount}`
	let link_usd =
		type === 'buyCourse'
			? `${HOST}/user/buyCourse/${course_id}/${amount}/stripe`
			: `${HOST}/user/buySubscribe/${type}/${amount}/stripe`

	const locate = target => {
		if (!window?.currentUser || window?.currentUser?.first_buy) {
			window.makeReactModal({
				icon: false,
				closeBtn: false,
				type: 'large',
				allowOverflow: false,
				reactElement: (
					<div className="typography typography--small typography--left">
						<h3 className="title">
							<span className="bold">
								<span className="color color--primary">Важно!</span>
							</span>
						</h3>
						<p>
							Чтобы получить полные расшифровки, после оплаты введите данные в оплаченные
							калькуляторы и нажмите «Рассчитать».
						</p>

						<div
							className="btn btn--primary btn--large btn--outline"
							onClick={() => {
								window.location.href = target
							}}
						>
							Понятно
						</div>
					</div>
				),
				onAccept: () => {
					window.location.href = target
				},
			})
		} else {
			window.location.href = target
		}
	}

	// if (!window.currentUser || window.currentUser.role_key !== 'admin') {
	// 	return locate(link)
	// }

	return locate(link)

	window.makeReactModal({
		icon: false,
		closeBtn: false,
		type: 'large',
		allowOverflow: false,
		reactElement: (
			<div className="typography typography--small typography--left">
				<h3 className="title">
					<span className="bold">
						Важно! <span className="color color--primary">Пожалуйста, прочтите</span> 😉🙏
					</span>
				</h3>
				<p>
					Сейчас в России не принимают карты иностранных банков. <b>Не беда!</b>
				</p>

				<p>
					<span className="color color--primary bold">1.</span> Если вы платите картой российского
					банка, не забудьте ВЫключить VPN, если вы его включали и нажмите на кнопку👇
				</p>

				<div
					className="btn btn--primary btn--large btn--outline"
					onClick={() => {
						locate(link)
					}}
				>
					Оплатить
				</div>

				<p>
					<span className="color color--primary bold">2.</span> Если платите картой другой страны,
					оплата будет в $ по текущему курсу. Ваша кнопка👇
				</p>

				<div
					className="btn btn--primary btn--large btn--outline"
					onClick={() => {
						locate(link_usd)
					}}
				>
					Оплатить в $
				</div>

				<p>
					<span className="color color--primary bold">3.</span> Если ничего не получается или есть
					вопросы - пишите, разберемся.
				</p>

				<div className="btn-items-group">
					<div className="touchable" onClick={() => locate(`https://t.me/ngshmidt`)}>
						<img src="/img/icon-tg-btn.svg" />
					</div>
					<div className="touchable" onClick={() => locate(`https://wa.me/79088001028`)}>
						<img src="/img/icon-wa-btn.svg" />
					</div>
					{/* <div */}
					{/*	className="touchable" */}
					{/*	onClick={() => locate(`https://instagram.com/ianashmidt.ru?utm_medium=copy_link`)} */}
					{/* > */}
					{/*	<img src="/img/icon-inst-btn.svg" /> */}
					{/* </div> */}
				</div>
			</div>
		),
		onAccept: () => locate(),
	})
}

window.makeModalConfirm = () => {
	return new Promise(resolve => {
		const locate = target => {
			modal.close()
			window.location.href = target
		}

		let modal = window.makeReactModal({
			icon: false,
			closeBtn: false,
			type: 'large',
			allowOverflow: false,
			onClose: () => {
				resolve('')
				modal.close()
			},
			onAccept: () => {
				resolve('')
				modal.close()
			},
			reactElement: (
				<div className="typography typography--small typography--left">
					<h3 className="title">
						<span className="bold">Выберите способ оплаты</span>
					</h3>
					<p>
						<span className="color color--primary bold">1.</span> Если вы платите картой российского
						банка, не забудьте ВЫключить VPN, если вы его включали и нажмите на кнопку👇
					</p>

					<div
						className="btn btn--primary btn--large btn--outline"
						onClick={() => {
							resolve('robo')
							modal.close()
						}}
					>
						Оплатить
					</div>

					<p>
						<span className="color color--primary bold">2.</span> Если платите картой другой страны,
						оплата будет в $ по текущему курсу. Ваша кнопка👇
					</p>

					<div
						className="btn btn--primary btn--large btn--outline"
						onClick={() => {
							resolve('stripe')
							modal.close()
						}}
					>
						Оплатить в $
					</div>

					<p>
						<span className="color color--primary bold">3.</span> Если ничего не получается или есть
						вопросы - пишите, разберемся.
					</p>

					<div className="btn-items-group">
						<div className="touchable" onClick={() => locate(`https://t.me/ngshmidt`)}>
							<img src="/img/icon-tg-btn.svg" />
						</div>
						<div className="touchable" onClick={() => locate(`https://wa.me/79088001028`)}>
							<img src="/img/icon-wa-btn.svg" />
						</div>
						{/* <div */}
						{/*	className="touchable" */}
						{/*	onClick={() => locate(`https://instagram.com/ianashmidt.ru?utm_medium=copy_link`)} */}
						{/* > */}
						{/*	<img src="/img/icon-inst-btn.svg" /> */}
						{/* </div> */}
					</div>
				</div>
			),
		})
	})
}
