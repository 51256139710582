const ANIMATE_DURATION = 0.5 * 1000;
const SNACK_TIMIG = 3 * 1000;

export type SNACK_TYPE = 'success' | 'error' | 'common' | string;

class SnacksContainer {
    snaks: Snack[] = [];
    root:HTMLElement | null = null;

    constructor() {
        this.root = document.createElement(`div`);
        this.root.classList.add(`snack-root`);
        document.body.appendChild(this.root);
    }

    push(snack: Snack){
        let snackNode = snack.getNode();
        this.root.appendChild(snackNode);
        this.snaks.push(snack);

        snack.show();
        this.root.scrollTo({
            top: snackNode.getBoundingClientRect().top,
            behavior: "smooth"
        });
    }

}


class Snack {
    node: HTMLElement | null = null;
    timer: number | null = null;
    constructor({ title, type }: { title: string, type: SNACK_TYPE }) {
        let snack = document.createElement("div");

        snack.innerHTML = `
            <span class="title">${title}</span>
        `;
        snack.classList.add(`snack`);
        snack.classList.add(`snack--${type}`);

        this.node = snack;

        this.bind();

        // snackRoot
        // this.show();


    }

    bind(){
        this.node.addEventListener(`click`, ()=>{
            clearTimeout(this.timer);
            this.hide();
        })
    }

    getNode(){
        return this.node;
    }

    show(){

        if(this.node) {
            this.node.classList.add(`animate__animated`);
            this.node.classList.add(`animate__fadeInDown`);

            setTimeout(() => {
                this.node.classList.remove(`animate__animated`);
                this.node.classList.remove(`animate__fadeInDown`);
            }, ANIMATE_DURATION);
        }

        this.timer = <any>setTimeout(()=>{
            this.hide();
        }, SNACK_TIMIG)
    }

    hide(){
        if(this.node){
            this.node.classList.add(`animate__animated`);
            this.node.classList.add(`animate__fadeOutRight`);

            setTimeout(() => {
                this.node.remove();
                // this.snack.classList.remove(`animate__animated`);
                // this.snack.classList.remove(`animate__fadeIn`);
            }, ANIMATE_DURATION - 100);
        }
    }

}



// @ts-ignore
window.snaks = new SnacksContainer();

const makeSnack = (type: SNACK_TYPE, title: string) => {

    // @ts-ignore
    window.snaks.push((new Snack({
        type,
        title
    })));

}

export { makeSnack }
