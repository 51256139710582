import React, { lazy, Suspense } from 'react'
import { render } from 'react-dom'
import { Loader } from '../components/Loader'
import { HOST } from '../config/host'

const TaroForm = lazy(() => import('../zones/TaroForm'))

setTimeout(() => {
	if (document.querySelector(`[data-make-taro-order]`)) {
		document.querySelectorAll(`[data-make-taro-order]`).forEach(element => {
			element.addEventListener('click', () => {
				window.location.href = `${HOST}/makeTaroOrder`
			})
		})
	}
	if (document.querySelector(`[data-taro-order]`)) {
		const makeForm = () => {
			render(
				<Suspense fallback={<Loader />}>
					<TaroForm />
				</Suspense>,
				document.querySelector(`[data-taro-order]`)
			)
			window.scroll({
				top: 0,
			})
		}
		makeForm()
	}
}, 0)
