import Modal from '../common/modal'
import isMobile from '../helpers/isMobile'
import { youtube_parser } from '../common/youtubeParser'

window.openLightBox = link => {
	const resultHtml = `
    <div class="modal modal--lightbox">
        <div class="modal-bg"></div>
        <div class="modal-content">
        	<div class="close" data-close-modal></div>
            
			<img src="${link}" />
		
        </div>
    </div>
    `

	let root = document.createElement(`div`)
	root.innerHTML = resultHtml
	document.body.appendChild(root)

	let target = root.children[0]

	let modal = new Modal({
		target,
		onOpen: () => {},
		onClose: () => {},
	})

	modal.open(false)
}

window.openLightBoxVideo = link => {
	let width = Math.min(window.innerWidth * 0.8, 960)
	let height = Math.min(window.innerHeight * 0.8, 540)
	let resultHtml = `
    <div class="modal  modal--lightbox modal--lightbox-video">
        <div class="modal-bg"></div>
        <div class="modal-content">
        	<div class="close" data-close-modal></div>
            
			<iframe type="text/html" width="${width}" height="${height}" src="https://www.youtube.com/embed/${youtube_parser(
		link
	)}" frameBorder="0"/>
		
        </div>
    </div>
    `

	if (link.endsWith(`.mp4`)) {
		resultHtml = `
    <div class="modal  modal--lightbox modal--lightbox-video">
        <div class="modal-bg"></div>
        <div class="modal-content">
        	<div class="close" data-close-modal></div>
            <video
			  width="${width}" height="${height}"
			  controls
			  autoplay>
			  <source
				src="${link}"
				type="video/mp4" />
			  Your browser doesn't support HTML5 video tag.
			</video>
        </div>
    </div>
    `
	}

	let root = document.createElement(`div`)
	root.innerHTML = resultHtml
	document.body.appendChild(root)

	let target = root.children[0]

	let modal = new Modal({
		target,
		onOpen: () => {},
		onClose: () => {
			target.remove()
		},
	})

	modal.open(false)
}
