import fetcher from '../common/fetcher'
import { OnTargetView } from '../common/OnTargetView'

OnTargetView({
	targets: `[data-views]`,
	onScroll: true,
	evaluate: async ({ element }) => {
		try {
			let response = await fetcher(`${window.location.href}?hitviews=true`)
			let text = await response.text()
			element.innerText = text && text.length < 100 ? text : '0'
		} catch (e) {
			console.log(e)
		}
	},
})
