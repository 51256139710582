// import { tns } from 'tiny-slider/src/tiny-slider'
// import LazyLoad from 'vanilla-lazyload'
import fetcher from '../common/fetcher'

setTimeout(() => {
	// if (document.querySelector('.page-news-complex')) {
	// 	let slider = tns({
	// 		container: document.querySelector('.page-news-complex').querySelector('.slider'),
	// 		navContainer: document.querySelector('.page-news-complex .slider-navs'),
	// 		items: 1,
	// 		loop: false,
	// 		// "autoHeight": false,
	// 		nav: true,
	// 		slideBy: 'page',
	// 		autoplay: false,
	// 		// fixedWidth: window.innerWidth,
	// 		mouseDrag: true,
	// 		prevButton: document.querySelector('.page-news-complex').querySelector('.prev'),
	// 		nextButton: document.querySelector('.page-news-complex').querySelector('.next'),
	// 		responsive: {
	// 			720: {},
	// 		},
	// 	})
	// }

	const wrap = function (toWrap, wrapper) {
		wrapper = wrapper || document.createElement('div')
		wrapper.classList.add('container-outer')
		wrapper.classList.add(`container-outer--${toWrap.tagName.toLocaleLowerCase()}`)
		toWrap.parentNode.appendChild(wrapper)
		return wrapper.appendChild(toWrap)
	}

	if (document.querySelector('.page-post .stage-3 .typography')) {
		const container = document.querySelector('.page-post .stage-3 .typography')
		;[...container.querySelectorAll('table')].map(element => {
			wrap(element)
		})
	}

	if (document.querySelector('div[data-load-cat]')) {
		;[...document.querySelectorAll('div[data-load-cat]')].map(element => {
			element.addEventListener('click', async () => {
				let page = parseInt(element.getAttribute('data-page'))
				let id = element.getAttribute('data-load-cat')

				let body = new FormData()
				body.append('cat_id', id)
				body.append('page', page)

				let res = await fetcher('/', {
					method: 'post',
					body,
				})

				element.setAttribute('data-page', page + 1)
				let base = element.parentElement.parentElement.querySelector('.grid')

				let html = await res.text()

				if (html.length < 10) {
					element.remove()
				}

				base.innerHTML += html

				window.lazyLoad.update()
			})
		})
	}
}, 0)
