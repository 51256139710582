import { OnTargetView } from '../common/OnTargetView'
import { youtube_parser } from '../common/youtubeParser'

OnTargetView({
	targets: '[data-lazy-video]',
	onScroll: true,
	evaluate: ({ element }) => {
		let link = element.getAttribute('data-lazy-video')
		let size = element.getBoundingClientRect()
		let iframe = document.createElement('iframe')
		iframe.type = 'text/html'
		iframe.width = size.width
		iframe.height = size.height
		iframe.src = `https://www.youtube.com/embed/${youtube_parser(link)}`
		iframe.frameBorder = 0
		element.appendChild(iframe)
		// 	< iframe
		// id = "ytplayer"
		// type = "text/html"
		// width = "640"
		// height = "360"
		// src = "http://www.youtube.com/embed/M7lc1UVf-VE?autoplay=1&origin=http://example.com"
		// frameBorder = "0" / >
	},
})
