let Cursor_X
let Cursor_Y
document.addEventListener('mousemove', onMouseUpdate, false)
document.addEventListener('mouseenter', onMouseUpdate, false)
function onMouseUpdate(e) {
	Cursor_X = e.clientX
	Cursor_Y = e.clientY

	// console.log(Cursor_X, Cursor_Y)
}

window.makePopUpSnack = title => {
	document.querySelectorAll('.pop-up-snack')?.forEach(e => e.classList.add(`pop-up-snack--hide`))
	let root = document.createElement(`div`)
	root.style.top = `${Cursor_Y}px`
	root.style.left = `${Cursor_X + 20}px`
	root.classList.add('pop-up-snack')
	root.innerHTML = title
	document.body.appendChild(root)
}
