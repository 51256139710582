import Smoother from '../common/smoother'

setTimeout(() => {
	if (!document.body.classList.contains('fixed-header')) {
		new Smoother({
			target: document.querySelector('.page-head'),
			offset: 0,
			modifyGlobalVariable: '--top-sticky',
		})
	}
})

// if (document.querySelector('.float-block')) {
// 	document.body.classList.add(`has-float-block`)
// }
