import { Debounce } from '../debounce'

class Smoother {
	hidden = true

	smoother_class = false

	last_scroll_direction = 'down'

	last_scroll_position = 0

	scroll_hold = 0

	constructor({ target, offset, inverse, modifyGlobalVariable }) {
		this.root = document.documentElement
		this.target = target
		window.addEventListener('scroll', () => {
			this.onScroll()
		})

		this.modifyGlobalVariable = modifyGlobalVariable
		if (this.modifyGlobalVariable) {
			this.root.style.setProperty(this.modifyGlobalVariable, `${20}px`)
		}

		this.last_scroll_position = window.scrollY
	}

	onScroll() {
		let scroll = Math.max(0, window.scrollY)

		if (scroll > this.target.offsetHeight) {
			if (!this.smoother_class) {
				this.target.classList.remove(`smoother`, `smoother--show`, `smoother--hide`)
				this.target.classList.add(`smoother`)
				this.smoother_class = true
			}
		} else if (this.smoother_class) {
			if (this.last_scroll_direction == 'up') {
				if (scroll == 0) {
					this.target.classList.remove(`smoother`, `smoother--show`, `smoother--hide`)
					this.smoother_class = false
					this.hide()
				}
			}
		}

		if (this.smoother_class) {
			if (this.last_scroll_direction == 'up') {
				// up
				if (scroll > this.last_scroll_position) {
					// scroll changed to down
					this.last_scroll_direction = 'down'
					this.scroll_hold = Math.abs(this.last_scroll_position - scroll)
				} else {
					// continue scrolling to up
					this.scroll_hold += Math.abs(this.last_scroll_position - scroll)

					if (this.scroll_hold > 30) {
						this.show()
					} else {
						this.hide()
					}
				}
			} else {
				// down
				if (scroll < this.last_scroll_position) {
					// scroll changed to up
					this.last_scroll_direction = 'up'
					this.scroll_hold = Math.abs(this.last_scroll_position - scroll)
				} else {
					// continue scrolling to down
					this.scroll_hold += Math.abs(this.last_scroll_position - scroll)

					this.hide()
				}
			}
		}

		this.last_scroll_position = scroll
	}

	show() {
		if (this.hidden) {
			if (this.modifyGlobalVariable) {
				this.root.style.setProperty(this.modifyGlobalVariable, `${this.target.offsetHeight + 20}px`)
			}

			this.target.classList.remove(`smoother--hide`)
			this.target.classList.add(`smoother--show`)
			this.hidden = false
		}
	}

	hide() {
		if (!this.hidden) {
			if (this.modifyGlobalVariable) {
				this.root.style.setProperty(this.modifyGlobalVariable, `${20}px`)
			}
			this.target.classList.remove(`smoother--show`)
			this.target.classList.add(`smoother--hide`)
			this.hidden = true
		}
	}
}
//
// class Smoother {
//
//   last_catch = 0;
//   last_scroll = 0;
//   scroll_deb = 0;
//   to_make = null;
//   last_direction_down = false;
//   direction_active = false;
//   last_make = 0;
//   target = null;
//   offset = 0;
//   inverse = false;
//   modifyGlobalVariable = false;
//   root = false;
//
//   trashhold = 5;
//
//   windowScrollDebounced = new Debounce(()=>{ this.scrollLocalListener(); }, this.trashhold);
//
//     constructor({
//         target,
//         offset,
//         inverse,
//         modifyGlobalVariable
//     }){
//
//
//       this.root = document.documentElement;
//       this.target = target;
//         this.offset = offset;
//         this.inverse = !!inverse;
//
//         if(modifyGlobalVariable){
//           this.modifyGlobalVariable = modifyGlobalVariable;
//         }
//
//         console.log(this.target);
//
//
//         window.addEventListener('scroll', ()=>{ this.windowScrollDebounced.fire(); });
//         this.windowScrollDebounced.fire();
//     }
//
//     scrollLocalListener() {
//
//       let scroll = window.scrollY;
//       //console.log(scroll);
//
//       //console.log(this.to_make);
//
//
//       if(scroll < this.last_scroll || scroll < 0){ // direction up
//
//
//         // if(body_elem.hasClass('scrollTen')){
//           if(this.last_direction_down || this.direction_active){
//
//             if(!this.last_direction_down){
//               this.last_direction_down = !this.inverse;
//               this.last_catch = scroll + (this.to_make - this.last_make);
//             }
//
//
//             if(this.direction_active){
//
//               if(Math.abs(this.last_catch - scroll) <= this.to_make || this.last_make){
//                 this.last_make = Math.max(0, this.to_make - Math.abs(this.last_catch - scroll));
//                 this.target.style.transform = `translate3d(0px, ${this.inverse ? '' : '-'}${this.last_make}px, 0px)`;
//
//
//                 if(this.modifyGlobalVariable){
//                   this.root.style.setProperty(this.modifyGlobalVariable, Math.min(this.target.offsetHeight, Math.abs(this.last_catch - scroll)) + 20 + "px");
//                 }
//               }
//               else {
//                 this.to_make = null;
//                 this.last_make = null;
//                 this.last_direction_down = this.inverse;
//                 this.direction_active = false;
//               }
//
//             }
//             else {
//               this.direction_active = true;
//               this.to_make = this.target.offsetHeight + this.offset;
//               this.last_catch = scroll;
//             }
//           }
//         // }
//
//
//       }
//       else { // direction down
//
//
//         // if(body_elem.hasClass('scrollTen')){
//           if(!this.last_direction_down || this.direction_active){
//
//             if(this.last_direction_down){
//               this.last_direction_down = this.inverse;
//               this.last_catch = scroll - this.last_make;
//             }
//
//             if(this.direction_active){
//
//               if(Math.abs(this.last_catch - scroll) <= this.to_make || this.last_make){
//                 this.last_make = Math.min(this.to_make, Math.abs(this.last_catch - scroll));
//
//                 this.target.style.transform = `translate3d(0px, ${this.inverse ? '' : '-'}${this.last_make}px, 0px)`;
//
//                 if(this.modifyGlobalVariable){
//                   this.root.style.setProperty(this.modifyGlobalVariable, Math.min(this.target.offsetHeight, this.target.offsetHeight - this.last_make) + 20 + "px");
//                 }
//               }
//               else {
//                 this.to_make = null;
//                 this.last_make = 0;
//                 this.last_direction_down = !this.inverse;
//                 this.direction_active = false;
//               }
//
//             }
//             else {
//               this.direction_active = true;
//               this.to_make = this.target.offsetHeight + this.offset;
//               this.last_catch = scroll;
//             }
//           }
//         // }
//
//       }
//
//
//
//
//       this.last_scroll = scroll;
//     };
// }

export default Smoother
