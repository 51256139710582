import Spoiler from '../common/spoiler'
import { OnTargetView } from '../common/OnTargetView'

OnTargetView({
	targets: '[spoiler], .spoiler',
	onScroll: true,
	evaluate: ({ element }) => {
		return new Spoiler({
			target: element,
			head: element.querySelector('.spoiler-head'),
			content: element.querySelector('.spoiler-content'),
		})
	},
})
