import { OnTargetView } from '../common/OnTargetView'

const ANIMATE_DURATION = 0.3 * 1000

interface DropDownProps {
	element: Element
}

class BasicDropDown {
	element: Element = null

	droppable: Element = null

	shadow: HTMLElement = null

	constructor({ element }: DropDownProps) {
		this.element = element
		this.droppable = element.querySelector('.droppable')

		console.log('this.droppable', this.droppable)

		this.bindEvents()

		let shadow = document.createElement('div')

		shadow.innerHTML = ``
		shadow.style.cssText = `display: none; position: fixed; width: 100%; height: 100%; top: 0; left: 0; z-index: 9;`

		this.shadow = shadow

		this.element.parentElement.appendChild(this.shadow)

		this.shadow.addEventListener('click', () => {
			this.hide()
		})
	}

	bindEvents() {
		this.element.querySelector('.current').addEventListener('click', async () => {
			if (this.element.classList.contains('active')) {
				this.hide()
			} else {
				this.show()
			}
		})

		this.element.querySelector('.item').addEventListener('click', async () => {
			this.hide()
		})
	}

	show() {
		this.shadow.style.display = `block`
		this.element.classList.add('active')
		this.contentAnimateIn()
	}

	hide() {
		this.shadow.style.display = `none`
		this.element.classList.remove('active')
		this.contentAnimateOut()
	}

	contentAnimateIn() {
		if (this.droppable) {
			this.droppable.classList.add(`animate__animated`)
			this.droppable.classList.add(`animate__fadeInUp`)

			setTimeout(() => {
				this.droppable.classList.remove(`animate__animated`)
				this.droppable.classList.remove(`animate__fadeInUp`)
			}, ANIMATE_DURATION)
		}
	}

	contentAnimateOut() {
		if (this.droppable) {
			this.droppable.classList.add(`animate__animated`)
			this.droppable.classList.add(`animate__fadeOutDown`)

			setTimeout(() => {
				this.droppable.classList.remove(`animate__animated`)
				this.droppable.classList.remove(`animate__fadeOutDown`)
			}, ANIMATE_DURATION)
		}
	}
}

OnTargetView({
	targets: '.dropdown',
	evaluate: ({ element }) => {
		let dropdown = new BasicDropDown({
			element,
		})
	},
})
