setTimeout(() => {
	;[...document.querySelectorAll(`[data-promotion-id]`)].map(element => {
		let key = `promotion_close_${element.getAttribute('data-promotion-id')}`
		if (window.localStorage.getItem(key)) {
			// element.classList.add('active')
		} else {
			element.classList.add('active')
			;[...element.querySelectorAll(`[data-dismiss]`)].map(btn => {
				btn.addEventListener('click', () => {
					window.localStorage.setItem(key, 'true')
					element.classList.remove('active')
				})
			})
		}
	})
}, 0)
