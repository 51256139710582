import { render } from 'react-dom'
import React, { lazy, Suspense } from 'react'
import { Loader } from '../components/Loader'
import { OnTargetView } from '../common/OnTargetView'
import { getHeightCommentsZone } from '../zones/RenderComments'

const RenderComments = lazy(() => import('../zones/RenderComments'))

OnTargetView({
	targets: '[data-comments-react]',
	onScroll: true,

	evaluate: ({ element }) => {
		// let load = false
		const fire = () => {
			const { height } = element.getBoundingClientRect()
			element.style.minHeight = `${height}px`
			// element.style.overflow = `hidden`

			console.log('LOADING COMMENTS', height)
			render(
				<Suspense fallback={<Loader height={height} text="Загружаем комментарии" />}>
					<RenderComments
						onLoad={() => {
							// element.style.height = 'auto'
							// element.style.overflow = 'auto'
							element.classList.add('loaded')
						}}
						loadHeight={height}
						url={element.getAttribute(`data-comments-react`)}
						replyUrl={element.getAttribute(`data-comments-reply-react`)}
						preCommentText={element.getAttribute(`data-pre-comment-text`)}
						title={element.getAttribute(`data-title`)}
						onlyComments={element.hasAttribute(`data-only-comments`)}
					/>
				</Suspense>,
				element
			)
		}
		fire()
		// element.addEventListener('click', () => {
		// 	if (!load) {
		// 		load = true
		// 		fire()
		// 	}
		// })
	},
})
