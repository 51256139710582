import TaporInitializer from '../common/tapor/taporInitializer'
import LinkGroupWatcher from '../common/linkGroupWatcher'
import { OnTargetView } from '../common/OnTargetView'

OnTargetView({
	targets: '.contents-content-of-page',
	onScroll: true,
	evaluate: () => {
		let links = [...document.querySelectorAll('.contents-content-of-page .links a')]
		let content_tapor = new TaporInitializer({
			elements: links,
			relative: true,
			scrollTo: true,
			// groupAttribute: 'btn-tapor-group',
		})

		new LinkGroupWatcher({
			elements: links,
			onActive: element => {
				element.taporTarget.setActive()
			},
		})
	},
})
