import { SNACK_TYPE } from '../common/snaks'
import Modal from '../common/modal'

export type ModalAuthType = {
	message: string
	type: SNACK_TYPE
	allowOverflow?: boolean
}
;(<any>window).makeModalAuth = ({
	message,
	type = 'common',
	allowOverflow = true,
}: ModalAuthType) => {
	const html = `
    <div class="modal modal-alert">
        <div class="modal-bg"></div>
        <div class="modal-close"></div>
        <div class="modal-content alert alert--${type}">
            <p class="typography">${message}</p>
        </div>
    </div>
    `

	let root = document.createElement(`div`)
	root.innerHTML = html
	document.body.appendChild(root)

	let target = root.children[0]

	let modal = new Modal({
		target,
		onOpen: () => {},
		onClose: () => {},
	})

	modal.open(allowOverflow)
}
