import fetcher from '../common/fetcher'

document.querySelectorAll(`[data-page-filter-feedback]`)?.forEach(async page => {
	let currentFilter = 'all'

	let batchReload = () => {
		zones.map(e => e.reload())
	}

	let rateItems = [...page.querySelectorAll(`.filter-zone .rate-line`)]?.map(rateItem => {
		let rate = parseInt(rateItem.getAttribute(`data-rate`), 10)
		let checkbox = rateItem.querySelector('.checkbox')

		const unselect = () => {
			checkbox.classList.remove('active')
		}

		rateItem.addEventListener('click', () => {
			if (currentFilter === rate) {
				currentFilter = 'all'
				unselect()
			} else {
				currentFilter = rate
				rateItems.map(e => e.unselect())
				checkbox.classList.add('active')
			}

			batchReload()
		})

		return {
			rateItem,
			checkbox,
			unselect,
		}
	})

	let zones = [...page.querySelectorAll(`[data-target]`)]?.map(zone => {
		let type = zone.getAttribute(`data-target`)
		let p = 0
		let loadMoreBtn = zone.querySelector(`[data-load-more]`)
		let items = zone.querySelector(`.items`)

		const loadMore = async (clean = false) => {
			if (loadMoreBtn && loadMoreBtn.classList.contains('disabled')) {
				return false
			}

			loadMoreBtn.classList.add('disabled')
			p += 1
			let result = await fetcher(`${HOST}/api/getFeedback`, {
				method: `POST`,
				body: {
					page: p,
					type,
					filter: currentFilter,
				},
			})

			let body = JSON.parse(await result.text())

			if (clean) {
				items.innerHTML = body.html
			} else {
				items.innerHTML += body.html
			}

			loadMoreBtn.classList.remove('disabled')
			if (!body.canLoadMore) {
				loadMoreBtn.remove()
			}
		}

		if (loadMoreBtn) {
			loadMoreBtn.addEventListener('click', loadMore)
		}

		return {
			zone,
			reload: () => {
				p = -1
				loadMore(true)
			},
		}
	})
})
