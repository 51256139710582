import scrollTo from '../common/smoothScrollTo'

setTimeout(() => {
	;[...document.querySelectorAll('[data-locate]')].map(element => {
		element.addEventListener('click', e => {
			let win = window.open(element.getAttribute('data-locate'), '_blank')
			win.focus()
			e.preventDefault()
		})
	})
	;[...document.querySelectorAll('a')].map(element => {
		let link = element.getAttribute('href')
		if (link === window.location.href || link === window.location.pathname) {
			element.classList.add('active')
		}
	})
	;[...document.querySelectorAll('[scroll-to]')].map(element => {
		element.addEventListener('click', () => {
			scrollTo({
				element: document.querySelector(element.getAttribute(`scroll-to`)),
			})
		})
	})
}, 0)

window.showAndHide = (show, hide) => {
	let showTarget = document.querySelector(show)
	let hideTarget = document.querySelector(hide)

	showTarget.style.display = 'block'
	hideTarget.style.display = 'none'

	setTimeout(() => {
		scrollTo({
			element: showTarget,
		})
	}, 50)
}
