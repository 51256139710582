
class Debounce {

    // func = null;
    // throttle = 100;
    // interval = null;
    // interval = null;

    is_paused = false;

    constructor(func, throttle = 100){
        this.func = func;
        this.throttle = throttle;
    }


    fire(...args){
        if(!this.is_paused){
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.func.apply(this, args);
            }, this.throttle);

        }
    }

    clear(){
        clearTimeout(this.timeout);
    }
    pause(){
        //clearTimeout(this.timeout);
        this.is_paused = true;
    }
    continue(){
        //clearTimeout(this.timeout);

        this.is_paused = false;
    }
}


export { Debounce }