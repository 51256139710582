import { render } from 'react-dom'
import React, { lazy, Suspense } from 'react'
import AccountZone from '../zones/AccountZone'

// setTimeout(() => {
// 	if (document.querySelector(`[data-render-account-zone]`)) {
// 		render(
// 			// <Skeleton circle width={isMobile() ? 28 : 50} height={isMobile() ? 28 : 50} />,
// 			<Suspense
// 				fallback={<Skeleton circle width={isMobile() ? 28 : 50} height={isMobile() ? 28 : 50} />}
// 			>
// 				<AccountZone />
// 			</Suspense>,
// 			document.querySelector(`[data-render-account-zone]`)
// 		)
// 	}
//
// 	if (document.querySelector(`[data-render-account-zone-footer]`)) {
// 		render(
// 			<Suspense fallback={<Skeleton height={isMobile() ? 0 : 46} />}>
// 				<AccountZone footer />
// 			</Suspense>,
// 			document.querySelector(`[data-render-account-zone-footer]`)
// 		)
// 	}
// }, 0)

setTimeout(() => {
	if (document.querySelector(`[data-render-account-zone]`)) {
		render(
			// <Skeleton circle width={isMobile() ? 28 : 50} height={isMobile() ? 28 : 50} />,
			<AccountZone />,
			document.querySelector(`[data-render-account-zone]`)
		)
	}

	if (document.querySelector(`[data-render-account-zone-footer]`)) {
		render(<AccountZone footer />, document.querySelector(`[data-render-account-zone-footer]`))
	}
}, 0)
