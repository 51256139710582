import { render } from 'react-dom'
import React, { lazy, Suspense } from 'react'
import { Loader } from '../components/Loader'
import { retry } from '../helpers/retry'
import { getFormTypeContentCls } from '../zones/Matrix/getFormTypeContentCls'

const ShowMatrix = lazy(() => import('../zones/Matrix/showDefault.tsx'))
const ShowMatrixChildren = lazy(() => import('../zones/Matrix/showChildrenDefault.tsx'))

const ShowMatrixFinance = lazy(() => import('../zones/Matrix/showFinanceDefault.tsx'))
const ShowMatrixSelfBrand = lazy(() => import('../zones/Matrix/showSelfBrandDefault.tsx'))
const ShowMatrixMatch = lazy(() => import('../zones/Matrix/showMatchDefault.tsx'))
const ShowMatrixMatchBusiness = lazy(() => import('../zones/Matrix/showMatchBusinessDefault.tsx'))

const batchForms = () => {
	if (document.querySelector(`[data-render-matrix]`)) {
		let el = document.querySelector(`[data-render-matrix]`)
		render(
			<Suspense fallback={<Loader height={getFormTypeContentCls('single')} />}>
				<ShowMatrix
					day={el.getAttribute('data-day')}
					month={el.getAttribute('data-month')}
					year={el.getAttribute('data-year')}
					name={el.getAttribute('data-name')}
					gender={el.getAttribute('data-gender')}
					dontMakeFull={el.getAttribute('data-dont-make-full') === 'true'}
				/>
			</Suspense>,
			el
		)
	}
	if (document.querySelector(`[data-render-matrix-children]`)) {
		let el = document.querySelector(`[data-render-matrix-children]`)
		render(
			<Suspense fallback={<Loader height={getFormTypeContentCls('children')} />}>
				<ShowMatrixChildren
					day={el.getAttribute('data-day')}
					month={el.getAttribute('data-month')}
					year={el.getAttribute('data-year')}
					name={el.getAttribute('data-name')}
					gender={el.getAttribute('data-gender')}
					dontMakeFull={el.getAttribute('data-dont-make-full') === 'true'}
				/>
			</Suspense>,
			el
		)
	}

	if (document.querySelector(`[data-render-matrix-finance]`)) {
		let el = document.querySelector(`[data-render-matrix-finance]`)
		render(
			<Suspense fallback={<Loader height={getFormTypeContentCls('finance')} />}>
				<ShowMatrixFinance
					day={el.getAttribute('data-day')}
					month={el.getAttribute('data-month')}
					year={el.getAttribute('data-year')}
					name={el.getAttribute('data-name')}
					gender={el.getAttribute('data-gender')}
					dontMakeFull={el.getAttribute('data-dont-make-full') === 'true'}
				/>
			</Suspense>,
			el
		)
	}
	if (document.querySelector(`[data-render-matrix-self-brand]`)) {
		let el = document.querySelector(`[data-render-matrix-self-brand]`)
		render(
			<Suspense fallback={<Loader height={getFormTypeContentCls('finance')} />}>
				<ShowMatrixSelfBrand
					day={el.getAttribute('data-day')}
					month={el.getAttribute('data-month')}
					year={el.getAttribute('data-year')}
					name={el.getAttribute('data-name')}
					gender={el.getAttribute('data-gender')}
					dontMakeFull={el.getAttribute('data-dont-make-full') === 'true'}
				/>
			</Suspense>,
			el
		)
	}

	if (document.querySelector(`[data-render-matrix-match]`)) {
		let el = document.querySelector(`[data-render-matrix-match]`)
		render(
			<Suspense fallback={<Loader height={getFormTypeContentCls('match')} />}>
				<ShowMatrixMatch
					day={el.getAttribute('data-day')}
					month={el.getAttribute('data-month')}
					year={el.getAttribute('data-year')}
					day2={el.getAttribute('data-day2')}
					month2={el.getAttribute('data-month2')}
					year2={el.getAttribute('data-year2')}
					name={el.getAttribute('data-name')}
					name2={el.getAttribute('data-name2')}
					dontMakeFull={el.getAttribute('data-dont-make-full') === 'true'}
				/>
			</Suspense>,
			el
		)
	}

	if (document.querySelector(`[data-render-matrix-match-business]`)) {
		let el = document.querySelector(`[data-render-matrix-match-business]`)
		render(
			<Suspense fallback={<Loader height={getFormTypeContentCls('match-business')} />}>
				<ShowMatrixMatchBusiness
					day={el.getAttribute('data-day')}
					month={el.getAttribute('data-month')}
					year={el.getAttribute('data-year')}
					day2={el.getAttribute('data-day2')}
					month2={el.getAttribute('data-month2')}
					year2={el.getAttribute('data-year2')}
					name={el.getAttribute('data-name')}
					name2={el.getAttribute('data-name2')}
					dontMakeFull={el.getAttribute('data-dont-make-full') === 'true'}
				/>
			</Suspense>,
			el
		)
	}
}

let mscrollh = false

if (
	window.location.hash ||
	window.scrollY > 0 ||
	window.location.href.includes('https://matrica-sudby.ru/lk/') ||
	window.location.href.includes('localhost')
) {
	mscrollh = true
	setTimeout(() => {
		batchForms()
	}, 300)
}

window.addEventListener('scroll', () => {
	if (mscrollh === false) {
		mscrollh = true

		setTimeout(() => {
			batchForms()
		}, 300)
	}
})
