// import '../less/main.less'
// import Rellax from 'rellax'
import scrollTo from './common/smoothScrollTo'
import fetcher from './common/fetcher'
import { HOST } from './config/host'
// import './helpers.js'
import './imports/make-ajax-form.js'
import './imports/simply-slider.js'
import './imports/page-head.js'
import './imports/make-auth-form.jsx'
import './imports/make-account-zone.jsx'
import './imports/make-matrix.jsx'
import './imports/make-matrix-forms.jsx'
import './imports/bind-copy.js'
import './imports/page-home.js'
import './imports/page-blog.js'
import './imports/modal.js'
import './imports/modalAuth.ts'
import './imports/modalMessage.ts'
import './imports/promise-zone'
// import './btn-tapor.js'
import './imports/show-more.ts'
import './imports/hit-views.js'
// import './page-hero-slider.js'
// import './page-about-us.js'
// import './page-news-grid.js'
// import './head-menu.js'
import './imports/spoiler.js'
import './imports/feedback-new-block.js'
import './imports/promotion-hiddens.js'
import './imports/lazy-video.js'
import './imports/make-taro-order.tsx'
// import './imports/ref-cabinet.tsx'
import './imports/render-comments.jsx'
import './imports/tabber-contents-wrapper.js'
import './imports/make-modal-buy.jsx'
// import './page-sales.js'
import './imports/page-post-content.js'
import './imports/locate-links.js'
import './imports/timer-to.js'
import './imports/stepper.js'
import './common/makeSaleCount.ts'
// import './makeCommentReply.js'
import './imports/tabber-links.js'
import './imports/make-snack.js'
// import './feedback-slider.js'
import './imports/stat-block.js'
import './imports/target-copy.js'
import './imports/dropdown.ts'
import './imports/make-modal-pick-installment.js'
// import './imports/two-path-block.js'
import './imports/make-popup-snack.js'
import './imports/makeSupportModal.js'
import './imports/make-page-rate.jsx'
import './pages/page-filter-feedback.js'
// import './imports/render-user-feedback.jsx'
import './imports/bind-lightbox.js'
import LazyLoad from 'vanilla-lazyload'
import { OnTargetView } from './common/OnTargetView'
import { makeBuyGiftModal } from './zones/BuyForGift'
import { copyTextToClipboard } from './common/copyToClipboard'
import { acceptPolicy } from './imports/acceptPolicy'
//
// const initializeSentry = async () => {
// 	let module = await import('@sentry/browser')
// 	let module2 = await import('@sentry/tracing')
// 	let Sentry = module
// 	let { Integrations } = module2
//
// 	Sentry.init({
// 		dsn: 'https://9ed73b57b6164682aff89d4da89625bb@o1098447.ingest.sentry.io/6122754',
// 		integrations: [new Integrations.BrowserTracing()],
//
// 		// Set tracesSampleRate to 1.0 to capture 100%
// 		// of transactions for performance monitoring.
// 		// We recommend adjusting this value in production
// 		tracesSampleRate: 1.0,
// 	})
// }
//
// setTimeout(initializeSentry, 0)

window.lazyLoad = new LazyLoad({
	// Your custom settings go here
})
//
// try {
//     window.csrf = document.querySelector('meta[name=csrf-token]').getAttribute(`content`);
//
// }
// catch(e) {
//     window.csrf = ``;
// }
//
// if (document.querySelectorAll('.rellax').length > 0) {
// 	const rellax = new Rellax('.rellax')
// }

window.scrollToElement = scrollTo
window.fetcher = fetcher
window.copyTextToClipboard = copyTextToClipboard
window.HOST = HOST
window.acceptPolicy = acceptPolicy

const floatBlock = document.querySelector(`.float-btn`)

const { body } = document

window.addEventListener('scroll', e => {
	if (floatBlock) {
		if (window.innerHeight < window.scrollY) {
			if (!floatBlock.classList.contains('active')) {
				floatBlock.classList.add('active')
				body.classList.add('show-float-bottom')
			}
		} else if (floatBlock.classList.contains('active')) {
			floatBlock.classList.remove('active')
			body.classList.remove('show-float-bottom')
		}
	}
})

if (navigator.appVersion.indexOf('Win') != -1) {
	document.body.classList.add('os-windows')
}

if (window.location.hash) {
	setTimeout(() => {
		scrollTo({
			element: window.location.hash,
		})
	}, 300)
}

window.makeBuyGiftModal = makeBuyGiftModal
// import './search-zone'
// import './render-reviews'
