if (window.location.pathname === '/') {
	if (document.querySelector('.to-action-2-block')) {
		let bg = document.querySelector('.to-action-2-block .bg')
		let firstItem = document.querySelector('.to-action-2-block .items .item:first-child')
		firstItem.classList.add('active')

		const bindHBG = () => {
			let active = document.querySelector('.to-action-2-block .items .item.active')
			bg.style.setProperty('background-image', `url(${active.getAttribute('data-bg')})`)
		}

		bindHBG()
		;[...document.querySelectorAll('.to-action-2-block .items .item')].map(link => {
			link.addEventListener('click', () => {
				document.querySelector('.to-action-2-block .items .item.active').classList.remove('active')
				link.classList.add('active')
				bindHBG()
			})
		})
	}
}
