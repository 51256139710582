import TaporsGroup from './taporGroup'

class TaporInitializer {

    tapors = [];
    taporGroups = [];
    relative = false;
    scrollTo = false;
    onShake = () => {};
    constructor({
        elements,
        elementsSelector,
        groupAttribute,
        relative,
        scrollTo,
        onShake
    }){
        this.relative = relative;

        if(scrollTo){
            this.scrollTo = scrollTo;
        }
        if(onShake){
            this.onShake = onShake;
        }
        if(elements){
            this.tapors = [...elements];
        }

        if(elementsSelector){
            this.tapors = [...document.querySelectorAll(elementsSelector)];

        }




        if(groupAttribute){
            this.taporGroups = this.tapors.reduce((r, a) => {
                r[a.getAttribute('btn-tapor-group')] = r[a.getAttribute('btn-tapor-group')] || [];
                r[a.getAttribute('btn-tapor-group')].push(a);
                return r;
            }, {});
        }
        else {
            this.taporGroups = { initial: this.tapors };
        }


        this.bindToGroups();
    }


    bindToGroups(){
        this.taporGroups = Object.keys(this.taporGroups).map(taporGroupKey=>{
            return (new TaporsGroup({
                key: taporGroupKey,
                elements: this.taporGroups[taporGroupKey],
                relative: this.relative,
                scrollTo: this.scrollTo
            }));
        });

        //console.log(this.taporGroups);
    }
    shakePlacer(){
        this.taporGroups.map(group=>{
            group.hidePlacer();
        });

        this.onShake();

        //console.log(this.taporGroups);
    }
}

export default TaporInitializer
