import { render } from 'react-dom'
import React, { lazy, Suspense } from 'react'
import { Loader } from '../components/Loader'
import { OnTargetView } from '../common/OnTargetView'

const PageRate = lazy(() => import('../zones/PageRate'))

OnTargetView({
	targets: '[data-page-rate]',
	evaluate: ({ element }) => {
		render(
			<Suspense fallback={<Loader />}>
				<PageRate url={element.getAttribute('data-page-rate')} />
			</Suspense>,
			element
		)
	},
})
